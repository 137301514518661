import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import Img from 'gatsby-image';
import ModalCarousel from './modalCarousel';
import GatsbyImage from 'components/GatsbyImage/GatsbyImage';
import CarouselPlaceholder from 'static/images/gooding.png';
import './carousel.scss';

const Carousel = ({
  items,
  size,
  showDotsOnHover,
  fluidImages,
  title,
  className = '',
}) => {
  const [modalCarouselIsOpen, setModalCarousel] = useState(false);
  const toggle = () => setModalCarousel(!modalCarouselIsOpen);

  const defaultSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
  };

  const fullScreenCarouselSettings = {
    ...defaultSettings,
    variableWidth: true,
    className: 'slider variable-width',
  };

  const settings = size === 'sm' ? defaultSettings : fullScreenCarouselSettings;

  const smallCarouselSlides = fluidImages
    ? fluidImages.map((item, index) => (
        <div key={`carouselKey_${index}`}>
          <Img fluid={item.fluid} alt={title} />
        </div>
      ))
    : items.map((item, index) => (
        <div key={`carouselKey${index}`}>
          <img src={item.src || CarouselPlaceholder} alt={item.title} />
        </div>
      ));

  const fullScreenCarouselSlides = fluidImages
    ? fluidImages.map((item, index) => (
        <div
          key={`carouselKey${index}`}
          className="largeSlide"
          onClick={() => toggle()}
          role="presentation"
        >
          <GatsbyImage image={item.fluid} title={title} />
        </div>
      ))
    : items.map((item, index) => (
        <div
          key={`carouselKey${index}`}
          className="largeSlide"
          onClick={() => toggle()}
          role="presentation"
        >
          <img src={item.src || CarouselPlaceholder} alt={item.title} />
        </div>
      ));

  return (
    <Fragment>
      <Slider
        {...settings}
        className={`appCarousel ${size !== 'sm' ? 'largeSlideContainer' : ''} ${
          showDotsOnHover ? 'hoverDots' : ''
        } ${className}`}
      >
        {size === 'sm' ? smallCarouselSlides : fullScreenCarouselSlides}
      </Slider>
      <ModalCarousel
        toggle={toggle}
        isOpen={modalCarouselIsOpen}
        items={items}
        fluidImages={fluidImages}
      />
    </Fragment>
  );
};

Carousel.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  size: PropTypes.oneOf(['sm', 'lg']),
};

Carousel.defaultProps = {
  size: 'sm',
  showDotsOnHover: false,
};

export default Carousel;
