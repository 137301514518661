import _ from 'lodash';
import { contentTypes } from 'utils/contentTypeConstants';
import * as countryJS from 'country-js';

const enabled = value => {
  const {
    auctionYear,
    venue,
    itemType,
    hasReservePrice,
    onlineBiddingAvailable,
  } = value;
  return (
    !!_.head(auctionYear) ||
    !!_.head(venue) ||
    !!_.head(itemType) ||
    hasReservePrice !== undefined ||
    onlineBiddingAvailable !== undefined
  );
};

const NUMBER_OF_MARKS = 30;
const MAX_ESTIMATE_PRICE = 3000000;

const getRangeData = estimates => {
  const marksPrices = [];
  const marksCounts = [];

  if (estimates.length === 0)
    return {
      initialData: { min: 0, max: 0 },
      marksCounts,
      marksPrices,
      step: 0,
      maxMark: 0,
    };

  const min = 0;
  const lowerBound = 30000;
  const higherBound = MAX_ESTIMATE_PRICE;
  const realMax = estimates[estimates.length - 1].value;

  // round the max price to the nearest multiple of lowerbound
  const tempMax =
    Math.ceil(estimates[estimates.length - 1].value / lowerBound) * lowerBound;

  const max =
    tempMax > higherBound
      ? higherBound
      : tempMax < lowerBound
      ? lowerBound
      : tempMax;
  const step = Math.floor((max - min) / NUMBER_OF_MARKS);
  const maxMark = step * NUMBER_OF_MARKS;

  let currentStep = step;
  let count = 0;
  let i = 0;
  while (currentStep <= maxMark && i < estimates.length) {
    if (estimates[i].value <= currentStep) {
      count += estimates[i].count;
      i++;
    } else {
      marksPrices.push(currentStep);
      marksCounts.push(count);
      count = 0;
      currentStep += step;
    }
  }
  // push count of the last step
  if (count !== 0) {
    marksPrices.push(currentStep);
    marksCounts.push(count);
  }
  //add counts of prices > max mark
  count = 0;
  while (i < estimates.length) {
    count += estimates[i].count;
    i++;
  }
  marksCounts.push(marksCounts.pop() + count);
  const initialData = { min, max: marksPrices[marksPrices.length - 1] };
  return { marksCounts, marksPrices, initialData, step, maxMark, realMax };
};

const PRIVATE_SALE_LISTING = 'Private Sale';

const getTag = vehicle => {
  if (vehicle.auctionType === PRIVATE_SALE_LISTING) {
    if (!vehicle.salePrice) {
      return 'AVAILABLE';
    } else {
      return 'SOLD';
    }
  } else {
    const today = Date.now();
    if (
      vehicle.auctionStartDate <= today &&
      (vehicle.auctionEndDate >= today ||
        vehicle.upcomingAuctionEndDate >= today)
    )
      return 'AVAILABLE';
    if (vehicle.auctionStartDate > today) return 'UPCOMING';
    else if (!vehicle.salePrice && vehicle.askingPrice) return 'AVAILABLE';
  }
  return '';
};

const getAuction = (vehicle, isNotTimestamp = false) => {
  const year = isNotTimestamp
    ? new Date(vehicle.auctionStartDate).getFullYear()
    : new Date(parseInt(vehicle.auctionStartDate)).getFullYear();
  return vehicle.auctionType === PRIVATE_SALE_LISTING
    ? 'Private Sale'
    : `${!Number.isNaN(year) ? year : ''} \xa0 | \xa0 ${vehicle.auctionName}`;
};

const getPrice = (vehicle, currency = 'USD') => {
  if (vehicle.salePrice)
    return `SOLD ${
      !vehicle.privateSalesPrice
        ? ` ${getPriceFormatted(vehicle.salePrice, currency)}`
        : ''
    }`;
  else {
    if (vehicle.askingPrice)
      return `Asking Price - ${
        getPriceFormatted(vehicle.askingPrice, currency) ?? ''
      }`;
    if (vehicle.lowEstimate || vehicle.highEstimate) {
      const reserve = `${vehicle.hasReservePrice ? '' : 'Without Reserve'}`;
      const estimates = getEstimates(
        vehicle.lowEstimate,
        vehicle.highEstimate,
        currency
      );
      return `${estimates} ${reserve && `| ${reserve}`}`;
    }
  }
  return '';
};

const getEstimates = (lowEstimate, highEstimate, currency) => {
  if (
    lowEstimate &&
    lowEstimate !== '0' &&
    highEstimate &&
    highEstimate !== '0'
  )
    return `${getPriceFormatted(lowEstimate, currency)} - ${getPriceFormatted(
      highEstimate,
      currency
    )}`;
  else if (lowEstimate && lowEstimate !== '0')
    return `${getPriceFormatted(lowEstimate, currency)}`;
  else if (highEstimate && highEstimate !== '0')
    return `${getPriceFormatted(highEstimate, currency)}`;
  else return '';
};

const getEstimatedValue = value => {
  if (value) {
    const splitValue = value.toString().split('$');
    return splitValue && splitValue.length > 1
      ? Number(splitValue[1].split('+')[0])
      : Number(splitValue[0].split('+')[0]);
  }
  return 0;
};
const getContentfulItemTitle = item => {
  if (!item || !item.__typename) return '';
  switch (item.__typename) {
    case contentTypes.VEHICLE:
      return `${item.modelYear} ${item.make ? item.make.name : ''} ${
        item.model
      }`;
    case contentTypes.AUTOMOBILIA:
      return item.title;
    default:
      return '';
  }
};

const getAlgoliaItemTitle = vehicle => {
  if (vehicle.itemType === 'Automobilia') return vehicle.title;
  else return `${vehicle.modelYear} ${vehicle.make} ${vehicle.model}`;
};

const getAuctionYear = subEvents => {
  let minStartDate = null;

  subEvents.forEach(subEvent => {
    if (subEvent && subEvent.startDate) {
      const startDate = Date.parse(subEvent.startDate);
      if (!minStartDate || startDate < minStartDate) minStartDate = startDate;
    }
  });
  return new Date(minStartDate).getFullYear();
};

const getPriceFormatted = (price, currency = 'USD') => {
  if (!price || price === '0') return '';
  return Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(price);
};

const getCurrencyCode = countryCode => {
  const countryResult = countryJS.search(countryCode);
  const country = !!countryResult.length && countryResult[0];
  return country?.currency?.currencyCode ?? 'USD';
};

const mapVenuesToCurrencies = (venues = []) => {
  const map = {};
  venues.forEach(
    venue =>
      (map[venue.contentful_id] = getCurrencyCode(
        venue.address?.addressCountry
      ))
  );
  return map;
};
export {
  enabled,
  getRangeData,
  getTag,
  getAuction,
  getPrice,
  getEstimatedValue,
  MAX_ESTIMATE_PRICE,
  getAlgoliaItemTitle,
  getContentfulItemTitle,
  getAuctionYear,
  getPriceFormatted,
  PRIVATE_SALE_LISTING,
  getCurrencyCode,
  mapVenuesToCurrencies,
  getEstimates,
};
