import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'reactstrap';
import Slider from 'react-slick';
import Button from 'components/Button';
import Img from 'gatsby-image';

const ModalCarousel = ({ toggle, isOpen, items, fluidImages }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    centerMode: true,
    className: 'center',
    customPaging: function (i) {
      return fluidImages ? (
        <img
          src={`${fluidImages[i].fluid.src}`}
          alt={`${fluidImages[i].title}`}
        />
      ) : (
        <img src={`${items[i].src}`} alt={`${items[i].title}`} />
      );
    },
    dotsClass: 'slick-dots slick-thumb',
  };

  const slides = fluidImages
    ? fluidImages.map((item, index) => (
        <div key={`carouselKeyModal${index}`} className="modalSliderContainer">
          <Img fluid={item.fluid} />
        </div>
      ))
    : items.map((item, index) => (
        <div key={`carouselKeyModal${index}`} className="modalSliderContainer">
          <img src={item.src} alt={item.title} />
        </div>
      ));

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="app-modal-carousel-container"
    >
      <Button handleClick={toggle} type="app-transparent-white-button">
        Close
      </Button>
      <Slider {...settings} className="app-modal-carousel">
        {slides}
      </Slider>
    </Modal>
  );
};

ModalCarousel.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default ModalCarousel;
