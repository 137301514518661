import * as React from 'react';

const Icon = ({ solid = false, color = '#000' }) => (
  <svg width={18} height={22} viewBox="0 0 18 22">
    <path
      fill={solid ? color : 'none'}
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M16.605 21l-7.556-5.556L1.494 21V3.222C1.494 1.995 2.46 1 3.653 1h10.793c1.192 0 2.159.995 2.159 2.222V21z"
    />
  </svg>
);

export default Icon;
